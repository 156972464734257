<template>
    <NoButtonModal :open="open" @close="$emit('cancel')">
      <button class="close-corner-x" type="button" @click.prevent="alertResponse('cancel')">
        <span class="material-icons-outlined dark-text">close</span>
      </button>
      <template v-if="clientListUsingProfile.length > 0">
        <h1>Info</h1>
        <p class="text-center">We can't delete this profile. Since below <b>{{clientListUsingProfile.length}}</b> client<span v-if="clientListUsingProfile.length > 1">(s)</span> using this profile.</p>

        <div class="block-client-list-using-profile">
            <div class="d-flex w-100 mb-2">
                <div class="w-45 ps-1 header-content">Client Name</div>
                <div class="w-55 ps-1 header-content">Email</div>
            </div>
            <div v-for="client in clientListUsingProfile" :key="client.id" class="member-detail-content">
                <div class="d-flex w-100 p-1">
                    <div class="w-45 client-name">
                        <span @click="$emit('handleMemberNameClick', client.id)" title="Click here to see member detail">{{ client.last_name ? client.last_name : '' }}, {{ client.first_name ? client.first_name : '' }}</span>
                    </div>
                    <div class="w-55 client-email">{{ client.email ? client.email : '' }}</div>
                </div>
            </div>
        </div>

        <button @click="alertResponse('cancel')" class="button secondary">Cancel</button>
      </template>
      <template v-else>
        <h1>Delete Alert</h1>
        <p>Are you sure you want to delete this profile?</p>
        <button @click="alertResponse('cancel')" class="button secondary">Cancel</button>
        <button @click="alertResponse('handleDelete')" class="button primary">Delete</button>
      </template>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    export default {
        name: 'AlertDeleteMemberProfile',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            clientListUsingProfile: {
                type: Array,
                required: true
            }
        },
        methods: {
            alertResponse(action) {
                if( action === 'handleDelete' ){
                    this.$emit('handleDelete');
                }
                else {
                    this.$emit('cancel');
                }
            }
        }
    };
</script>
<style scoped>
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #ccc3c3;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--base-theme-color);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--base-theme-color);
    }
</style>
