<template>
    <div class="company-setting-main">
        <!-- TAB INPUTS -->
        <input id="page-tab-alerts" class="page-tab-input" name="page-tab-input" type="radio" checked />
        <input id="page-tab-forms" class="page-tab-input" name="page-tab-input" type="radio" />
        <input id="page-tab-notifications" class="page-tab-input" name="page-tab-input" type="radio" />
        <input id="page-tab-tags" class="page-tab-input" name="page-tab-input" type="radio" />
        <input id="page-tab-users-clients" class="page-tab-input" name="page-tab-input" type="radio" />
        <input id="page-tab-send-messages" class="page-tab-input" name="page-tab-input" type="radio" />
        <input v-if="rows['files'].length" id="page-tab-files" class="page-tab-input" name="page-tab-input" type="radio" />
        <!-- <input id="page-tab-misc" class="page-tab-input" name="page-tab-input" type="radio" /> -->
        <input v-if="userIsSuperAdmin" id="page-tab-member-profiles" class="page-tab-input" name="page-tab-input" type="radio" />

        <!-- TABS CONTAINER -->
        <div class="company-settings-header page-tabs-container d-flex justify-content-between">
            <!-- <h1>Company Settings</h1> -->
            <label class="page-tab page-tab-alerts top-menu d-flex justify-content-center align-items-center" for="page-tab-alerts">
                <span class="material-symbols-outlined top-menu-icon"> warning </span>
                Alerts
            </label>
            <label class="page-tab page-tab-forms top-menu d-flex justify-content-center align-items-center" for="page-tab-forms">
                <span class="material-symbols-outlined top-menu-icon"> article </span>
                Forms
            </label>
            <label class="page-tab page-tab-notifications top-menu d-flex justify-content-center align-items-center" for="page-tab-notifications">
                <span class="material-symbols-outlined top-menu-icon"> notifications </span>
                 Member Notifications
            </label>
            <label class="page-tab page-tab-tags top-menu d-flex justify-content-center align-items-center" for="page-tab-tags">
                <span class="material-symbols-outlined top-menu-icon"> group </span>
                Member Tags
            </label>
            <label class="page-tab page-tab-users-clients top-menu d-flex justify-content-center align-items-center" for="page-tab-users-clients">
                <span class="material-symbols-outlined top-menu-icon"> schedule </span>
                Session Timeout
            </label>
            <label v-if="userIsAdmin || userIsSuperAdmin" class="page-tab page-tab-send-messages top-menu d-flex justify-content-center align-items-center" for="page-tab-send-messages">
                <span class="material-symbols-outlined top-menu-icon"> chat_bubble </span>
                 Send Messages
            </label>
            <label v-if="rows['files'].length" class="page-tab page-tab-files top-menu d-flex justify-content-center align-items-center" for="page-tab-files">
                <span class="material-symbols-outlined top-menu-icon"> warning </span>
                Files
            </label>
            <!--            <label class="page-tab page-tab-misc" for="page-tab-misc">Misc</label>-->
            <!-- <label v-if="userIsSuperAdmin" class="page-tab page-tab-member-profiles top-menu d-flex justify-content-center align-items-center" for="page-tab-member-profiles">
                <span class="material-symbols-outlined top-menu-icon"> group </span>
                Member Profiles
            </label> -->
        </div>
        <!-- TABBED PAGE SECTIONS -->
        <section class="tabbed-page alerts">
            <!-- <ChirpSettings v-if="!loading" :data_source="rows['alert']" :level="'company'" :level_id="level_id" /> -->
            <AlertsSetting v-if="!loading" :data_source="rows['alert']" :level="'company'" :level_id="level_id" />
        </section>

        <section class="tabbed-page forms">
            <!-- <ChirpSettings v-if="!loading" :data_source="rows['form']" :level="'company'" :level_id="level_id" /> -->
            <FormsSettings v-if="!loading" :data_source="rows['form']" :level="'company'" :level_id="level_id" />
        </section>

        <section class="tabbed-page notifications">
            <ClientNotificationsSetting v-if="!loading" :data_source="rows['notification']" :level="'company'" :level_id="level_id" />

            <!-- <ChirpSettings
                v-if="!loading"
                :data_source="rows['notification']"
                :level="'company'"
                :level_id="level_id"
            /> -->
        </section>

        <section class="tabbed-page tags">
            <TagsSetting v-if="!loading" :data_source="rows['tags']" :level="'company'" :level_id="level_id" />
            <!-- <ChirpSettings v-if="!loading" :data_source="rows['tags']" :level="'company'" :level_id="level_id" /> -->
        </section>

        <section class="tabbed-page users_clients">
            <!-- <ChirpSettings v-if="!loading" :data_source="rows['users_clients']" :level="'company'" :level_id="level_id" /> -->
            <UsersAndClientsSetting v-if="!loading" :data_source="rows['users_clients']" :level="'company'" :level_id="level_id" />
        </section>

        <section v-if="rows['files'].length" class="tabbed-page files">
            <ChirpSettings v-if="!loading" :data_source="rows['files']" :level="'company'" :level_id="level_id" />
        </section>

        <section v-if="userIsAdmin || userIsSuperAdmin" class="tabbed-page send-messages">
            <SendMessages v-if="!loading" />
        </section>

        <!--        <section class="tabbed-page misc">-->
        <!--            <ChirpSettings v-if="!loading" :data_source="rows['misc']" :level="'company'" :level_id="level_id" />-->
        <!--        </section>-->
        <section v-if="userIsSuperAdmin" class="tabbed-page member-profiles">
            <!-- <ChirpSettings v-if="!loading" :data_source="rows['users_clients']" :level="'company'" :level_id="level_id" /> -->
            <MemberProfiles />
        </section>
    </div>
</template>

<script>
    const api_root = 'settings/list/company';

    import ChirpSettings from '@/components/general/list/ChirpSettings';
    import MemberProfiles from '../../components/company/member-profiles/MemberProfiles.vue';
    import TagsSetting from '../../components/settings/TagsSetting.vue';
    import UsersAndClientsSetting from '../../components/settings/UsersAndClientsSetting.vue';
    import ClientNotificationsSetting from '../../components/settings/ClientNotificationsSetting.vue';
    import FormsSettings from '../../components/settings/FormsSetting.vue';
    import AlertsSetting from '../../components/settings/AlertsSetting.vue';
    import SendMessages from '../../components/settings/SendMessages.vue';
    import { Roles } from '@/util/globalConstants'

    export default {
        name: 'CompanySettings',
        components: { ChirpSettings, MemberProfiles, TagsSetting, UsersAndClientsSetting, ClientNotificationsSetting, FormsSettings, AlertsSetting, SendMessages },
        data() {
            return {
                loading: 1,
                rows: {
                    alert: [],
                    form: [],
                    notification: [],
                    tags: [],
                    misc: [],
                    users_clients: [],
                    files: [],
                },
                level_id: 0
            };
        },
        computed: {
            userIsSuperAdmin() {
                if (this.$store.state.user.role_id==Roles.SUPER_ADMIN) {
                    return true;
                } else return false;
            },
            userIsAdmin(){
                if (this.$store.state.user.role_id==Roles.ADMIN) {
                    return true;
                } else return false;
            }
        },
        methods: {
            async init() {
                this.level_id = this.$store.state.user.company_id;
                const res = await this.$api.get(`/${api_root}/${this.level_id}`);
                let d, row, obj;
                for (d in res.data) {
                    row = res.data[d];
                    if (row.value_options && row.value_options.length) {
                        try {
                            obj = JSON.parse(row.value_options);
                        } catch (e) {
                            obj = {};
                        }
                        if (obj.apiroot) {
                            const body = {
                                criteria: { page: { num_per_page: 100, page_num: 1 } }
                            };
                            // this is where need to grab the api list to render a dynamic dropdown
                            // row.value_options (STRING) = {"apiroot":"/email_templates", "text":"email_template_title", "value":"id"}
                            let apires = await this.$api.post(obj.apiroot, body);
                            let text = obj.text;
                            let value = obj.value;
                            obj = [{ text: ' -- Disabled --', value: 0 }];
                            for (let apival in apires.data.rows) {
                                let o2 = {
                                    text: apires.data.rows[apival][text],
                                    value: apires.data.rows[apival][value]
                                };
                                obj.push(o2);
                            }
                            row.value_options = JSON.stringify(obj);
                        }
                    }

                    switch (row.category) {
                        case 'alert':
                            this.rows['alert'].push(row);
                            break;
                        case 'form':
                            this.rows['form'].push(row);
                            break;
                        case 'notification':
                            this.rows['notification'].push(row);
                            break;
                        case 'tags':
                            this.rows['tags'].push(row);
                            break;
                        case 'users_clients':
                            this.rows['users_clients'].push(row);
                            break;
                        case 'files':
                            this.rows['files'].push(row);
                            break;
                        case 'payment': // not here
                        case 'insurance': // not here
                        case 'billing': // not here
                            break;
                        default:
                            this.rows['misc'].push(row);
                    }
                }
                // this.rows = res.data
                this.loading = 0;
            }
        },
        async created() {
            await this.init();
        }
    };
</script>

<style scoped>

    .top-menu:checked {
        background: #FFF;
        color: #3551AF;
    }

    @media only screen and (max-width: 1100px) {
        .top-menu {
            padding: 1.5% 2%;
            margin: 0%;
            font-weight: 500;
            font-size: 12px;
            font-family: 'Roboto';
            border-radius: 15px 15px 0 0;
            color: #5155C3;
            opacity: .95;
            background: #FFFFFF;
        }

        .top-menu-icon {
            font-size: 20px;
            margin-right: .5rem;
        }

        svg {
            width: 20px;
            margin-right: .5rem;
        }
    }

    @media only screen and (min-width: 1100px) and (max-width: 1300px) {
        .top-menu {
            padding: 1.5% 2%;
            margin: 0%;
            font-weight: 500;
            font-size: 15px;
            font-family: 'Roboto';
            border-radius: 15px 15px 0 0;
            color: #5155C3;
            opacity: .95;
            background: #FFFFFF;
        }

        .top-menu-icon {
            font-size: 25px;
            margin-right: .5rem;
        }

        svg {
            width: 28px;
            margin-right: .5rem;
        }
    }

    @media only screen and (min-width: 1300px) and (max-width: 1700px) {
        .top-menu {
            padding: 1.5% 2%;
            margin: 0%;
            font-weight: 500;
            font-size: 18px;
            font-family: 'Roboto';
            border-radius: 15px 15px 0 0;
            color: #5155C3;
            opacity: .95;
            background: #FFFFFF;
        }

        .top-menu-icon {
            font-size: 30px;
            margin-right: .5rem;
        }
        
        svg {
            width: 35px;
            margin-right: .5rem;
        }
    }

    @media only screen and (min-width: 1700px) {
        .top-menu {
            padding: 1.5% 3%;
            margin: 0%;
            font-weight: 500;
            font-size: 20px;
            font-family: 'Roboto';
            border-radius: 15px 15px 0 0;
            color: #5155C3;
            opacity: .95;
            background: #FFFFFF;
        }

        .top-menu-icon {
            font-size: 40px;
            margin-right: .5rem;
        }

        svg {
            width: 45px;
            margin-right: .5rem;
        }
    }

</style>

<style>
    /* ### Custom scroll ### */
    /* width */
    ::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #ccc3c3;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--base-theme-color) !important;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--base-theme-color) !important;
    }
</style>